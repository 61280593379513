import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../app/AppContext";
import { types } from "../helpers/types";
import { ReactComponent as CarIco } from "../assets/svg/icocar.svg";
import { Divider, Input } from "antd";

export const Step1Page = () => {
  const { state, dispatch } = useContext(AppContext);
  const [seguro, setSeguro] = useState(state.userData?.seguro ? state.userData.seguro : 12500);
  const [suma, setSuma] = useState(`$ ${seguro}`);
  const [optGas, setOptGas] = useState(state.userData?.gas);
  const fAlto = 60;

  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  }, []);

  useEffect(() => {
    setSuma(`$ ${seguro}`);
  }, [seguro]);

  const hSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: types.setAll,
      payload: {
        step: 2,
        userData: {
          ...state.userData,
          año: Number.parseInt(e.target[0][e.target[0].selectedIndex].value),
          marca: e.target[1][e.target[1].selectedIndex].value,
          gas: e.target[2].checked ? true : false,
          seguro: seguro,
        },
      },
    };
    dispatch(data);
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }
  const años = [...range(1970, 2020)];
  const marcas = [
    "Alfa Romeo",
    "Audi",
    "BMW",
    "Citroen",
    "Dodge",
    "Ferrari",
    "Fiat",
    "Ford",
    "Honda",
    "Jaguar",
    "Lexus",
    "Maserati",
    "Mazda",
    "Mercedez-Benz",
    "Mitsubishi",
    "Nissan",
    "Opel",
    "Peugeot",
    "Porsche",
    "Renault",
    "Seat",
    "Skoda",
    "Subaru",
    "Toyota",
    "Volkswagen",
    "Volvo",
    "Abarth",
    "Mini",
    "Ds",
    "Suzuki",
    "Morgan",
    "Aston martin",
    "Kia",
    "Hyundai",
    "Mg",
    "Ssangyoung",
    "Bentley",
    "Lamborghini",
    "Lotus",
    "Smart",
    "Dacia",
    "Isuzu",
    "Jeep",
    "Land-rover",
    "Mahindra",
    "Dfsk",
    "Tesla",
    "Alpine",
    "Cupra",
    "Jiayuan",
    "Zhidou",
    "Invicta",
    "Lynk&co",
    "Swm",
    // "Levc", "", "", "", "", "", ""
  ];
  marcas.sort((a, b) => {
    return a.localeCompare(b);
  });

  const Validar = ({ msgSuccess = "", msgError = "" }) => {
    return (
      <>
        {msgSuccess.length > 0 && (
          <div className="valid-feedback p-0">
            <div className="alert alert-success mb-0 p-2">
              <div className="flex-1">
                <i className="bi bi-check-circle"> {msgSuccess} </i>
              </div>
            </div>
          </div>
        )}
        {msgError.length > 0 && (
          <div className="invalid-feedback p-0">
            <div className="alert alert-error mb-0 p-2">
              <div className="flex-1">
                <i className="bi bi-x-circle"> {msgError} </i>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const optChange = (e) => {
    e.target.value === "si" ? setOptGas(true) : setOptGas(false);
  };

  const menos = () => {
    seguro > 12500 ? setSeguro(seguro - 100) : setSeguro(12500);
  };
  const mas = () => {
    seguro < 16500 ? setSeguro(seguro + 100) : setSeguro(16500);
  };

  const InputA = () => {
    const selectBefore = (
      <button className="m-0 p-2" type="button" onClick={menos}>
        <i className="bi bi-dash" style={{ color: "rgb(180,180,180)", fontSize: 40 }}></i>
      </button>
    );
    const selectAfter = (
      <button className="m-0 p-2" type="button" onClick={mas}>
        <i className="bi bi-plus" style={{ color: "rgb(180,180,180)", fontSize: 40 }}></i>
      </button>
    );

    return (
      <Input.Group compact>
        <Input
          readOnly
          value={suma}
          suffix={selectAfter}
          prefix={selectBefore}
          style={{ margin: 0, padding: 0, height: 50, textAlign: "center", borderRadius: "10px" }}
        />
      </Input.Group>
    );
  };

  const btnBack = () => {
    dispatch({ type: types.setAll, payload: { ...state, step: 0 } });
  };

  return (
    <div className="row position-relative d-inline-block" style={{ margin: "auto", width: 400 }}>
      <div className="col">
        <button className="d-flex justify-content-center align-items-center" onClick={btnBack}>
          <i
            className="bi bi-arrow-left-circle"
            style={{ color: "rgb(240,40,80)", fontSize: "2rem" }}
          ></i>
          <span className="ms-2"> Volver </span>
        </button>

        <p />

        <span className="h2" style={{ color: "rgb(80,80,80)" }}>
          {`¡Hola, `}
        </span>
        <span className="h2" style={{ color: "rgb(240,40,80)" }}>
          {`${state.user?.nombre}!`}
        </span>

        <br />

        <div className="mt-2 text-muted">Completa los datos de tu auto</div>
      </div>

      <p />

      <form
        className="d-inline-block w-100 top-0 start-0 p-0 needs-validation"
        onSubmit={hSubmit}
        noValidate
      >
        <div className="row m-0">
          <div className="col p-0">
            <div className="col p-0 form-floating">
              <select
                required
                defaultValue={state.userData?.año ? state.userData.año : ""}
                autoComplete="off"
                id="validationCustom00"
                className="form-select select select-bordered fw-bolder text-muted"
                style={{ height: fAlto }}
              >
                <option disabled value="">
                  Seleccione un año
                </option>
                {años.map((dat) => (
                  <option key={dat} value={dat}>
                    {" "}
                    {dat}{" "}
                  </option>
                ))}
              </select>
              <label htmlFor="validationCustom00">Año</label>
              <Validar msgError="¡Seleccione un año!" />
            </div>

            <p />

            <div className="col form-floating">
              <select
                required
                defaultValue={state.userData?.marca ? state.userData.marca : ""}
                autoComplete="off"
                id="validationCustom01"
                className="form-select select select-bordered fw-bolder text-muted"
                style={{ height: fAlto }}
              >
                <option disabled value="">
                  Seleccione una marca
                </option>
                {marcas.map((dat) => (
                  <option key={dat} value={dat}>
                    {" "}
                    {dat}{" "}
                  </option>
                ))}
              </select>
              <label htmlFor="validationCustom01">Marca</label>
              <Validar msgError="¡Seleccione una marca!" />
            </div>
          </div>

          <div
            className="col d-none d-xxl-block position-absolute start-100 ms-5 p-0"
            style={{ width: 200 }}
          >
            <span className="fw-bolder text-muted">AYUDA</span>

            <Divider
              className="row m-0 p-0 mt-2 mb-1"
              type="horizontal"
              style={{ height: "4px" }}
            />

            <div className="row m-0">
              <div className="col p-0">
                <div className="row">
                  <span className="fw-bolder text-muted"> ¿No encuentras el modelo? </span>
                </div>
                <div className="row">
                  <span>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.google.com.pe">
                      Click Aquí
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-auto p-0 me-4">
                {" "}
                <CarIco />{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="col d-xs-block d-xxl-none p-0">
          <p />
          <div
            className="row w-100 m-0 p-2"
            style={{ backgroundColor: "rgba(200,220,240,0.5)", borderRadius: "10px" }}
          >
            <div className="col-auto p-0 me-4">
              {" "}
              <CarIco />{" "}
            </div>
            <div className="col p-0">
              <div className="row">
                <span className="fw-bolder text-muted"> ¿No encuentras el modelo? </span>
              </div>
              <div className="row">
                <span>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.google.com.pe">
                    Click Aquí
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <p />

        <div className="col form-floating">
          <div className="row w-100 m-0 p-0">
            <div className="label m-0 p-0" style={{ width: "70%" }}>
              <span className="fw-bolder text-muted">¿Tu auto es a gas?</span>
            </div>

            <div className="row m-0 p-0" style={{ width: "30%" }}>
              <div className="label p-0" style={{ width: "40%" }}>
                <input
                  required
                  className="radio radio-primary"
                  id="validationCheck01"
                  type="radio"
                  name="opt"
                  value="si"
                  defaultChecked={optGas}
                  onChange={optChange}
                />
                <label className="form-check-label" htmlFor="validationCheck01">
                  Si
                </label>
              </div>

              <div className="m-0 p-0" style={{ width: "20%" }}></div>

              <div className="label p-0" style={{ width: "40%" }}>
                <input
                  required
                  className="radio radio-primary"
                  id="validationCheck02"
                  type="radio"
                  name="opt"
                  value="no"
                  defaultChecked={!optGas}
                  onChange={optChange}
                />
                <label className="form-check-label" htmlFor="validationCheck02">
                  No
                </label>
              </div>
            </div>
          </div>

          <input
            required
            hidden
            className="form-check-input"
            id="validationCheck03"
            type="radio"
            name="opt"
            value=""
          />
          <Validar msgError="¡Escoja una opción!" />
        </div>

        <Divider type="horizontal" className="row" />

        <div className="row m-0 p-0">
          <div className="col-xs-12 col-lg-6 m-0 p-0">
            <div className="label m-0 p-0">
              <span className="fw-bolder text-muted">Indica la suma asegurada</span>
            </div>

            <div className="row m-0 p-0">
              <div className="col p-0" style={{ width: "23%" }}>
                <span className="text-muted"> MIN $12,500 </span>
              </div>

              <Divider
                type="vertical"
                className="row m-0 mt-1"
                style={{ width: "3%", height: "16px" }}
              />

              <div className="col position-relative p-0" style={{ width: "23%" }}>
                <span className="text-muted position-absolute end-0"> MAX $16,500 </span>
              </div>
            </div>
          </div>

          <p className="d-xs-block d-lg-none" />

          <div className="col-xs-12 col-lg-6 m-0 p-0 ps-lg-3">
            <InputA />
          </div>
        </div>

        <br />

        <div className="row w-100 m-0 p-0">
          <div className="d-grid gap-2 p-0">
            <button
              type="submit"
              className="btn btn-danger"
              style={{ backgroundColor: "rgb(240,40,80)", height: fAlto }}
            >
              Continuar &nbsp; &gt;
            </button>
          </div>
        </div>

        <br />
      </form>
    </div>
  );
};
