import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../app/AppContext";
import { types } from "../helpers/types";
import { Divider } from "antd";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ReactComponent as SegurI } from "../assets/svg/seguro.svg";
import { ReactComponent as Detail } from "../assets/svg/detalles.svg";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const Step2Page = () => {
  const { state, dispatch } = useContext(AppContext);
  // eslint-disable-next-line
  const [subtotal, setSubtotal] = useState(state.seguData?.subtotal ? state.seguData.subtotal : 0);
  // eslint-disable-next-line
  const [benefits, setBenefits] = useState(
    state.seguData?.beneficios ? state.seguData.beneficios : []
  );
  // eslint-disable-next-line
  const [mensual, setMensual] = useState(state.seguData?.mensual ? state.seguData.mensual : 20);
  const [tab, setTab] = React.useState("1");
  const fAlto = 60;

  const dataTemp = {
    roboTotal: 50,
    roboParcial: 15,
    choque_accidente: 20,
    llanta: ["Llanta de repuesto", "Análisis de motor", "Aros gratis"],
    luzroja: ["Pago de las multas", "Reparación civil", "Hospital gratis"],
    atropello: ["Ambulancia en el acto", "Médico emergencista", "Abogado gratis"],
  };

  const onSegurAdd = (añadidos) => () => {
    dispatch({ type: types.setAll, payload: { userSegur: { ...state.userSegur, ...añadidos } } });

    añadidos.llantaRobo &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal + dataTemp.roboParcial,
            beneficios: [...benefits, ...dataTemp.llanta],
            mensual: mensual + dataTemp.roboParcial,
          },
        },
      });
    añadidos.choqueLuz &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal + dataTemp.choque_accidente,
            beneficios: [...benefits, ...dataTemp.luzroja],
            mensual: mensual + dataTemp.choque_accidente,
          },
        },
      });
    añadidos.choqueEvitam &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal + dataTemp.choque_accidente,
            beneficios: [...benefits, ...dataTemp.atropello],
            mensual: mensual + dataTemp.choque_accidente,
          },
        },
      });
  };

  const onSegurDel = (añadidos) => () => {
    dispatch({ type: types.setAll, payload: { userSegur: { ...state.userSegur, ...añadidos } } });

    añadidos.llantaRobo === false &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal - dataTemp.roboParcial,
            mensual: mensual - dataTemp.roboParcial,
            beneficios: benefits.filter((dat) => {
              return dataTemp.llanta.indexOf(dat) === -1;
            }),
          },
        },
      });
    añadidos.choqueLuz === false &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal - dataTemp.choque_accidente,
            mensual: mensual - dataTemp.choque_accidente,
            beneficios: benefits.filter((dat) => {
              return dataTemp.luzroja.indexOf(dat) === -1;
            }),
          },
        },
      });
    añadidos.choqueEvitam === false &&
      dispatch({
        type: types.setAll,
        payload: {
          seguData: {
            subtotal: subtotal - dataTemp.choque_accidente,
            mensual: mensual - dataTemp.choque_accidente,
            beneficios: benefits.filter((dat) => {
              return dataTemp.atropello.indexOf(dat) === -1;
            }),
          },
        },
      });
  };

  const Seguro1 = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card className="mb-2" sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="seguro">
              LL
            </Avatar>
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="mostrar más"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          title={
            <span className="h5" style={{ color: "rgb(80,80,80)" }}>
              {" "}
              Llanta robada{" "}
            </span>
          }
          subheader={
            !state.userSegur?.llantaRobo ? (
              <button
                className="label link-accent"
                onClick={onSegurAdd({ llantaRobo: true })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-plus-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Agregar </span>
              </button>
            ) : (
              <button
                className="label link-accent"
                onClick={onSegurDel({ llantaRobo: false })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-dash-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Quitar </span>
              </button>
            )
          }
          className="pb-0"
        />
        <CardContent className="pt-0 ms-4 ps-5">
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to cook together with
              your guests. Add 1 cup of frozen peas along with the mussels, if you like.
            </Typography>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  const Seguro2 = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card className="mb-2" sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="seguro">
              CL
            </Avatar>
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="mostrar más"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          title={
            <span className="h5" style={{ color: "rgb(80,80,80)" }}>
              {" "}
              Choque y/o pasarte la luz roja{" "}
            </span>
          }
          subheader={
            !state.userSegur?.choqueLuz ? (
              <button
                className="label link-accent"
                onClick={onSegurAdd({ choqueLuz: true })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-plus-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Agregar </span>
              </button>
            ) : (
              <button
                className="label link-accent"
                onClick={onSegurDel({ choqueLuz: false })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-dash-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Quitar </span>
              </button>
            )
          }
          className="pb-0"
        />
        <CardContent className="pt-0 ms-4 ps-5">
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to cook together with
              your guests. Add 1 cup of frozen peas along with the mussels, if you like.
            </Typography>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  const Seguro3 = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <Card className="mb-2" sx={{ maxWidth: 345 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: blue[500] }} aria-label="seguro">
              AP
            </Avatar>
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="mostrar más"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
          title={
            <span className="h5" style={{ color: "rgb(80,80,80)" }}>
              {" "}
              Atropello en la vía Evitamiento{" "}
            </span>
          }
          subheader={
            !state.userSegur?.choqueEvitam ? (
              <button
                className="label link-accent"
                onClick={onSegurAdd({ choqueEvitam: true })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-plus-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Agregar </span>
              </button>
            ) : (
              <button
                className="label link-accent"
                onClick={onSegurDel({ choqueEvitam: false })}
                style={{ width: 85 }}
                type="button"
              >
                <i className="bi bi-dash-circle" style={{ fontSize: 25 }}></i>
                <span className=""> Quitar </span>
              </button>
            )
          }
          className="pb-0"
        />
        <CardContent className="pt-0 ms-4 ps-5">
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to cook together with
              your guests. Add 1 cup of frozen peas along with the mussels, if you like.
            </Typography>
          </Collapse>
        </CardContent>
      </Card>
    );
  };

  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  }, []);

  const hSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: types.setAll,
      payload: {
        step: 100,
      },
    };
    dispatch(data);
  };

  const Incluyentes = () => {
    const incluye = [...benefits];

    return incluye.length > 0 ? (
      incluye.map((dat, i) => (
        <div key={i} className="d-flex align-items-center p-0">
          <i className="bi bi-check" style={{ color: "rgb(40,200,80)", fontSize: 20 }}>
            &nbsp;
          </i>
          <span> {dat} </span>
        </div>
      ))
    ) : (
      <div className="d-flex align-items-center p-0">
        <i className="bi bi-check" style={{ color: "rgb(40,200,80)", fontSize: 20 }}>
          &nbsp;
        </i>
        <span> cobertura básica </span>
      </div>
    );
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const btnBack = () => {
    dispatch({ type: types.setAll, payload: { ...state, step: 1 } });
  };

  return (
    <>
      <div
        className="row d-inline-block w-xs-400 w-lg-600 p-0 ps-2 pe-2"
        style={{ margin: "auto" }}
      >
        <div className="col p-0">
          <button className="d-flex justify-content-center align-items-center" onClick={btnBack}>
            <i
              className="bi bi-arrow-left-circle"
              style={{ color: "rgb(240,40,80)", fontSize: "2rem" }}
            ></i>
            <span className="ms-2"> Volver </span>
          </button>

          <p />

          <span className="h2" style={{ color: "rgb(80,80,80)" }}>
            Mira las coberturas
          </span>

          <br />

          <div className="mt-2 text-muted">Conoce las coberturas de tu plan</div>
        </div>

        <p />

        <form className="d-inline-block w-100 p-0 needs-validation" onSubmit={hSubmit} noValidate>
          <div className="row m-0">
            <div className="col-12 col-lg-7 p-0 pe-3 position-relative">
              <div className="col-12 col-lg-7 p-0 ps-4 position-absolute translate-middle-y top-50">
                <div className="text-muted"> Placa: {state.userData?.placa} </div>
                <div className="h5" style={{ color: "rgb(80,80,80)" }}>
                  {state.userData?.marca}
                </div>
                <button className="fw-bold link-accent" style={{ fontSize: 12 }} onClick={btnBack}>
                  EDITAR
                </button>
              </div>
              <Detail height="180px" width="100%" preserveAspectRatio="none" />
            </div>

            <div
              className="col-5 p-0 ps-3 d-none d-lg-block"
              style={{ overflowY: "visible", height: fAlto + fAlto + 14 }}
            >
              <div className="row m-0">
                <div className="col p-0">
                  <span className="h3 m-0" style={{ color: "rgb(80,80,80)" }}>
                    ${mensual}
                  </span>
                  <div className="m-0 text-muted">mensuales</div>
                </div>

                <div className="col-3 p-0">
                  <SegurI />
                </div>
              </div>

              <Divider className="m-0 p-0 mt-3 mb-3" type="horizontal" style={{ height: "4px" }} />

              <div className="col p-0">
                <div className="col p-0">
                  <span className="fw-bolder text-muted"> El precio incluye: </span>
                </div>
                <div className="col p-0">
                  <Incluyentes />
                </div>

                <br />

                <div className="d-grid gap-2 p-0">
                  <button
                    type="submit"
                    className="btn btn-danger"
                    style={{ backgroundColor: "rgb(240,40,80)", height: fAlto }}
                  >
                    Lo quiero
                  </button>
                </div>
              </div>
            </div>

            <p />
            <p />

            <div className="col-12 col-lg-7 p-0 pe-2">
              <span className="h5 m-0" style={{ color: "rgb(80,80,80)" }}>
                Agregar o quitar coberturas
              </span>

              <p />

              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="Coberturas">
                      <Tab label="PROTEGE A TU AUTO" value="1" sx={{ width: 100, fontSize: 12 }} />
                      <Tab
                        label="PROTEGE A LOS QUE TE RODEAN"
                        value="2"
                        sx={{ width: 130, fontSize: 12 }}
                        disabled
                      />
                      <Tab
                        label="MEJORA TU PLAN"
                        value="3"
                        sx={{ width: 110, fontSize: 12 }}
                        disabled
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Seguro1 />
                    <Seguro2 />
                    <Seguro3 />
                  </TabPanel>
                  <TabPanel value="2">Item Two</TabPanel>
                  <TabPanel value="3">Item Three</TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>
        </form>
      </div>

      <div className="position-fixed bottom-0 start-0 w-100 d-xs-block d-lg-none p-0">
        <div
          className="d-flex justify-content-between w-100 m-0 p-2"
          style={{ backgroundColor: "rgba(200,220,240,0.5)", height: 66 }}
        >
          <div className="m-0 p-0">
            <span className="h3 m-0" style={{ color: "rgb(80,80,80)" }}>
              ${mensual}
            </span>
            <br />
            <span className="m-0 p-0 text-muted">mensual</span>
          </div>

          <div className="d-grid gap-2 p-0" style={{ width: 120 }}>
            <button
              type="button"
              onClick={hSubmit}
              className="btn btn-danger"
              style={{ backgroundColor: "rgb(240,40,80)", height: "100%" }}
            >
              Lo quiero
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
