import React, { useState, forwardRef, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar, SnackbarContent } from 'notistack'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
  typography: {
    fontWeight: 'bold',
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      // 'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 12,
    allVariants: {
      color: 'black',
    }
  },
  root: {
    [ theme.breakpoints.up('sm') ]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: 'rgba(250, 220, 100, 1)',
    width: '100%',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: 'rgba(180, 180, 180, 1)',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}))

const CustomSnack = forwardRef(( props, ref ) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  const [ expanded, setExpanded ] = useState(false)

//   console.log( props )

  const handleExpandClick = useCallback( () => {
      setExpanded( oldExpanded => !oldExpanded )
  }, [])

  const handleDismiss = useCallback( () => {
      closeSnackbar( props.id )
  }, [ props.id, closeSnackbar ])

  return (
    <SnackbarContent ref={ ref } className={ classes.root }>
      <Card className={ classes.card }>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography variant="subtitle2" className={ classes.typography }>
             { props.message }
          </Typography>
          <div className={ classes.icons }>
            <IconButton
              aria-label="Show more"
              className={ expanded ? classes.expandOpen : classes.expand }
              onClick={ handleExpandClick }
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton className={ classes.expand } onClick={ handleDismiss }>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={ expanded } timeout="auto" unmountOnExit>
          <Paper className={ classes.collapse }>
            <Typography gutterBottom> PDF ready </Typography>
            <Button size="small" className={ classes.button }>
              <CheckCircleIcon className={ classes.checkIcon } />
                Download now
            </Button>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
})

export default CustomSnack