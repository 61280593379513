import React, { useContext, useEffect } from "react";
import { Col, Layout, Row } from "antd";
import { Formulario } from "../components/Formulario";
import { ReactComponent as FondoDesktop } from "../assets/svg/fondoDesk.svg";
import { ReactComponent as FondoMobile } from "../assets/svg/fondoMobil.svg";
import { AppContext } from "../app/AppContext";
import { types } from "../helpers/types";
import getUser from "../helpers/getUser";

const { Footer } = Layout;

export const MainPage = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    !state.user &&
      getUser()
        .then((res) => dispatch({ type: types.setAll, payload: { user: res } }))
        .catch((err) => console.log(err))
        .finally();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid min-vh-100 p-0">
      <Row className="h-100" style={{ paddingTop: 60 }}>
        <FondoDesktop
          className="d-none d-xl-block"
          height="93.8vh"
          width="750px"
          preserveAspectRatio="none"
        />
        <FondoMobile
          className="d-block d-xl-none"
          height="60vh"
          width="100%"
          preserveAspectRatio="none"
        />

        <Col flex="auto" style={{ maxHeight: "93.8vh", overflowY: "auto" }}>
          <Row
            className="position-relative translate-middle d-inline-block top-50 start-50 p-0"
            style={{ margin: "auto", width: 410 }}
          >
            {/* <br /> */}
            <Formulario history={history} />
            <p />
            <br />
            <div className="bottom-0 p-0" style={{ height: 70 }}></div>
          </Row>

          <div className="position-absolute bottom-0 p-0" style={{ width: "100%" }}>
            <Footer style={{ textAlign: "center" }}>Nox Design ©2021 Created by Kevin CEO</Footer>
          </div>
        </Col>
      </Row>
    </div>
  );
};
