import { types } from "../helpers/types";

const appReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case types.setOne:
      return { ...payload };

    case types.setAll:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default appReducer;
