import React, { useContext, useEffect } from "react";
import { types } from "../helpers/types";
import { AppContext } from "../app/AppContext";
import { useSnackbar as caca } from "notistack";
import { msgSnack } from "./MiSnack";

export const Formulario = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const { enqueueSnackbar, closeSnackbar } = caca();
  // const [ snakKey, setSnakKey ] = useState()
  const fAlto = 60;

  useEffect(() => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms).forEach(function (form) {
      form.addEventListener(
        "submit",
        function (event) {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  }, []);

  const hSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: types.setAll,
      payload: {
        step: 1,
        userData: {
          ...state.userData,
          documento: e.target[0][e.target[0].selectedIndex].value,
          numeroDoc: e.target[1].value,
          celular: e.target[2].value,
          placa: e.target[3].value,
          terms: e.target[4].checked,
        },
      },
    };
    dispatch(data);
    msgSnack("Registrado con éxito", "success", null, enqueueSnackbar, closeSnackbar);
  };

  const Validar = ({ msgSuccess = "", msgError = "" }) => {
    return (
      <>
        {msgSuccess.length > 0 && (
          <div className="valid-feedback p-0">
            <div className="alert alert-success mb-0 p-2">
              <div className="flex-1">
                <i className="bi bi-check-circle"> {msgSuccess} </i>
              </div>
            </div>
          </div>
        )}
        {msgError.length > 0 && (
          <div className="invalid-feedback p-0">
            <div className="alert alert-error mb-0 p-2">
              <div className="flex-1">
                <i className="bi bi-x-circle"> {msgError} </i>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <form className="d-inline-block w-100 top-0 start-0 p-0 needs-validation" onSubmit={hSubmit} noValidate>
      <span className="h3" style={{ color: "rgb(80,80,80)" }}>
        {" "}
        Déjanos tus datos{" "}
      </span>
      <p />

      <div className="row m-0">
        <div className="p-0 m-0" style={{ width: "40%" }}>
          <select
            required
            defaultValue={state.userData?.documento ? state.userData?.documento : ""}
            autoComplete="off"
            id="validationCustom00"
            className="form-select select select-bordered form-select label"
            style={{ height: fAlto, color: "rgb(80,80,80)" }}
          >
            <option disabled value="">
              Seleccione Tipo
            </option>
            <option key="dni" value="dni">
              {" "}
              DNI{" "}
            </option>
            <option key="pas" value="pas">
              {" "}
              Pasaporte{" "}
            </option>
            <option key="lic" value="lic">
              {" "}
              Licencia de conducir{" "}
            </option>
          </select>
          <Validar msgError="¡ Elija una opción !" />
        </div>
        <div className="form-floating p-0 m-0" style={{ width: "60%" }}>
          <input
            required
            defaultValue={state.userData?.numeroDoc ? state.userData?.numeroDoc : ""}
            autoComplete="off"
            id="validationCustom01"
            className="form-control input input-bordered"
            style={{ height: fAlto }}
          />
          <label className="label-text" htmlFor="validationCustom01">
            Número de documento
          </label>
          <Validar msgError="¡Ingrese su documento!" />
        </div>
      </div>

      <p />

      <div className="row m-0 form-floating">
        <input
          required
          defaultValue={state.userData?.celular ? state.userData?.celular : ""}
          size="large"
          autoComplete="off"
          id="validationCustom02"
          className="form-control input input-bordered"
          style={{ height: fAlto }}
        />
        <label className="label-text" htmlFor="validationCustom02">
          Celular
        </label>
        <Validar msgError="¡Ingrese un número!" />
      </div>

      <p />

      <div className="row m-0 form-floating">
        <input
          required
          defaultValue={state.userData?.placa ? state.userData?.placa : ""}
          size="large"
          autoComplete="off"
          id="validationCustom03"
          className="form-control input input-bordered"
          style={{ height: fAlto }}
        />
        <label className="label-text" htmlFor="validationCustom03">
          Placa
        </label>
        <Validar msgError="¡Es necesario una placa!" />
      </div>

      <br />

      <div className="row m-0">
        <input required type="checkbox" id="validationCustom04" className="form-check-input" style={{ width: "5%", height: 20 }} />

        <div className="p-0" style={{ width: "2%" }} />

        <div className="form-check-label p-0" style={{ width: "93%" }}>
          <span> Acepto la Política de </span>
          <a target="_blank" rel="noopener noreferrer" href="https://www.google.com.pe">
            Protección de Datos Personales
          </a>
          <span> y los </span>
          <a target="_blank" rel="noopener noreferrer" href="https://www.google.com.pe">
            Términos y Condiciones.
          </a>
        </div>
        <Validar msgError="¡Acepte los Términos y Condiciones!" />
      </div>

      <br />

      <div className="row m-0">
        <div className="d-grid gap-2">
          <button type="submit" className="btn btn-danger" style={{ backgroundColor: "rgb(240,40,80)", height: fAlto }}>
            Cotízalo
          </button>
        </div>
      </div>
    </form>
  );
};
