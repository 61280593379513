import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { AppContext } from "../app/AppContext";
import { ReactComponent as FinalDesktop } from "../assets/svg/finalDesk.svg";
import { ReactComponent as FinalMobile } from "../assets/svg/finalMobil.svg";
import { types } from "../helpers/types";

export const FinalPage = () => {
  const { state, dispatch } = useContext(AppContext);
  // eslint-disable-next-line
  const [xs, setXs] = useState();
  // eslint-disable-next-line
  const [sm, setSm] = useState();
  // eslint-disable-next-line
  const [md, setMd] = useState();
  // eslint-disable-next-line
  const [lg, setLg] = useState();
  // eslint-disable-next-line
  const [xl, setXl] = useState();
  // eslint-disable-next-line
  const [xxl, setXxl] = useState();
  const altoNav = "93.3vh";

  const onExit = () => {
    dispatch({
      type: types.setOne,
      payload: { theme: "light", step: 0 },
    });
  };

  useEffect(() => {
    const width = window.innerWidth;
    width < 576 ? setXs(true) : setXs(false);
    width >= 576 ? setSm(true) : setSm(false);
    width >= 768 ? setMd(true) : setMd(false);
    width >= 992 ? setLg(true) : setLg(false);
    width >= 1200 ? setXl(true) : setXl(false);
    width >= 1400 ? setXxl(true) : setXxl(false);

    return () => {
      window.removeEventListener("beforeunload");
      window.removeEventListener("resize");
    };
  }, []);

  const handleResize = () => {
    // The width below which the mobile view should be rendered
    // The current width of the viewport
    const width = window.innerWidth;
    width < 576 ? setXs(true) : setXs(false);
    width >= 576 ? setSm(true) : setSm(false);
    width >= 768 ? setMd(true) : setMd(false);
    width >= 992 ? setLg(true) : setLg(false);
    width >= 1200 ? setXl(true) : setXl(false);
    width >= 1400 ? setXxl(true) : setXxl(false);
  };

  window.addEventListener("beforeunload", onExit);
  window.addEventListener("resize", handleResize);

  return (
    <div className="container-fluid p-0">
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        sx={{ height: altoNav, marginTop: "6.7vh" }}
      >
        <Grid item xs="auto">
          <FinalDesktop
            className="p-0 d-none d-lg-block"
            height={altoNav}
            width="500px"
            preserveAspectRatio="none"
          />
          <FinalMobile
            className="d-block d-lg-none"
            height="60vh"
            width="100vw"
            preserveAspectRatio="none"
          />
        </Grid>

        <Grid item xs>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: lg ? altoNav : "33.3vh", overflowY: "auto" }}
          >
            <Grid item sx={{ width: xs ? 340 : 470 }}>
              <span className="h1" style={{ color: "rgb(240,40,80)" }}>
                ¡Te damos la bienvenida!
              </span>
              <br />
              <span className="h1" style={{ color: "rgb(80,80,80)" }}>
                Cuenta con nosotros para proteger tu vehículo
              </span>
              <p />
              <span>
                Enviamos la confirmación de compra de tu Plan Vehicular Tracking a tu correo: <br />
                {`${state.user?.correo}!`}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
