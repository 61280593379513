import React, { useEffect, useReducer } from 'react'
import { AppContext } from './app/AppContext'
import MainRouter from './routers/MainRouter'
import appReducer from './app/appReducer'

function App () {
   const init = { theme: 'light' }
   const local = JSON.parse( localStorage.getItem('reducer') )
   const datos = local === null ? init : local
   const [ state, dispatch ] = useReducer( appReducer, datos )

   useEffect(() => {
      localStorage.setItem( 'reducer', JSON.stringify( state ) )
   }, [ state ])

   return (
      <AppContext.Provider value={{ state, dispatch }}>
         <MainRouter />
      </AppContext.Provider>
   )
}

export default App
