import React, { useContext } from "react";
// eslint-disable-next-line
import { Col, Progress, Row, Steps } from "antd";
import { Route, Routes } from "react-router-dom";
import { AppContext } from "../app/AppContext";
import { types } from "../helpers/types";
import { FinalPage } from "../pages/FinalPage";
import { MainPage } from "../pages/MainPage";
import { Step1Page } from "../pages/Step1Page";
import { Step2Page } from "../pages/Step2Page";

const { Step } = Steps;

export const StepRouter = () => {
  const { state, dispatch } = useContext(AppContext);

  const steps = [
    {
      title: "Datos del auto",
      describe: "Características del auto",
    },
    {
      title: "Arma tu plan",
      describe: "Personaliza tu seguro",
    },
  ];

  const onChange = (current) => {
    // current < state.step & state.step > 0 &&
    dispatch({ type: types.setAll, payload: { step: current + 1 } });
  };

  return (
    <>
      <div className="position-fixed d-xs-block d-lg-none w-100" style={{ marginTop: 62 }}>
        <div className="row w-100 m-0 px-4" style={{ padding: "13px 0 13px 0" }}>
          <div className="col-xs-auto p-0" style={{ width: "80px" }}>
            <span className="p-0">Paso {state.step} de 2</span>
          </div>
          <div className="col">
            <div className="p-0"></div>
            <Progress percent={state.step * 50} showInfo={false} />
          </div>
        </div>
        <hr className="dropdown-divider m-0 min-vw-100" />
      </div>

      <div className="container-fluid min-vh-100 p-0">
        <Row className="h-100 pt-xs-9 pt-lg-6">
          <div className="d-none d-lg-block" style={{ backgroundColor: "lightgrey", width: 360 }}>
            <div className="position-relative d-inline-block translate-middle-x mt-5 start-50 p-0">
              <Steps current={state.step - 1} onChange={onChange} direction="vertical">
                {steps.map((item) => (
                  <Step key={item.title} title={item.title} description={item.describe} />
                ))}
              </Steps>
            </div>
          </div>

          <Col className="d-flex vh-xs-80-8 vh-lg-93" flex="auto" style={{ overflowY: "auto" }}>
            <Routes>
              <Route
                path="/"
                Component={(props) => {
                  if (state.step === 0 || state.step === undefined) return <MainPage {...props} />;
                  if (state.step === 1) return <Step1Page {...props} />;
                  if (state.step === 2) return <Step2Page {...props} />;
                  if (state.step === 100) return <FinalPage {...props} />;
                }}
              />
            </Routes>
          </Col>
        </Row>
      </div>
    </>
  );
};
