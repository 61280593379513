const getUser = async() => {
   
   const resp = await fetch('https://randomuser.me/api/')
   const { results } = await resp.json()
   const { name, dob, picture, location, gender, email, cell } = results[0]
   const { country, state, city, postcode } = location
   const { large, medium, thumbnail } = picture
   const { first, last, title } = name
   const { date, age } = dob
   
   const detalles = {
      titulo: title,
      nombre: first,
      apellido: last,
      edad: age,
      genero: gender,
      celular: cell,
      correo: email,
      cumple: date,
      pais: country,
      estado: state,
      ciudad: city,
      postal: postcode,
      imagen: {
         grande: large,
         mediana: medium,
         pequeña: thumbnail,
      }
   }

   return detalles
}

export default getUser
