import React from 'react'
import CustomSnack from './CustomSnack'
// eslint-disable-next-line
import { Slide, Grow, Fade, Zoom, Collapse, Button } from '@material-ui/core'

export const msgSnack = ( msg = "", varz = "", content ="", enqSnck, clsSnck ) => {

   // eslint-disable-next-line
   const continuar = ( key ) => {
      clsSnck( key )
      // history.push('/step')
   }
   
   const action = ( key ) => (
      <>
         {/* <button
            className="btn btn-sm btn-success m-1 pt-0"
            onClick={ () => continuar( key ) }
         > Continuar </button> */}
   
         <button
            className="btn btn-sm btn-error m-1 pt-0"
            onClick={ () => clsSnck( key ) }
         > Cerrar </button>
      </>
   )
   
   // eslint-disable-next-line
   const contenido = ( key, message ) => (
      <CustomSnack id={ key } message={ message } />
   )
   
   // customized
   // const iconVariant = {
   //    success: '✅',
   //    error: '✖️',
   //    warning: '⚠️',
   //    info: 'ℹ️',
   // }
   const props = {
      variant: varz,
      anchorOrigin: {
         vertical: 'top',
         horizontal: 'center',
      },
      TransitionComponent: Slide,
      // autoHideDuration: 4000,
      preventDuplicate: true,
      persist: true,
      action,
      content,
   }
   // variant could be success, error, warning, info, or default
   return(enqSnck( msg, props ))
}
