import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { AppContext } from "../app/AppContext";
import { MainPage } from "../pages/MainPage";
// import 'daisyui/dist/themes.css'
// import 'daisyui/dist/styled.css'
// import 'daisyui/dist/full.css'
import { Row } from "antd";
import { SnackbarProvider } from "notistack";
import { ReactComponent as Rimac } from "../assets/svg/rimac.svg";
import { FinalPage } from "../pages/FinalPage";
import { StepRouter } from "./StepRouter";

const MainRouter = () => {
  const { state } = useContext(AppContext);

  return (
    <Row className="min-vh-100" data-theme={state?.theme}>
      <SnackbarProvider maxSnack={4}>
        <div className="position-fixed top-0 start-0 ms-3" style={{ zIndex: 101, marginTop: 20 }}>
          <Rimac height="100%" />
        </div>

        <div className="position-fixed row top-0 end-0 mt-3 me-3" style={{ zIndex: 101, width: 280 }}>
          <div className="p-0" style={{ width: "62%" }}>
            <span className="text-primary"> ¿ Tienes alguna duda ? </span>
          </div>

          <div className="p-0" style={{ width: "8%" }}>
            <i className="text-primary bi bi-telephone-fill"></i>
          </div>

          <div className="p-0" style={{ width: "30%" }}>
            <span className="text-primary"> (043) 602227 </span>
          </div>
        </div>

        <div className="position-fixed" style={{ backgroundColor: "white", zIndex: 100, paddingTop: 62 }}>
          <hr className="dropdown-divider m-0 min-vw-100" />
        </div>

        <Router>
          <Routes>
            <Route
              path="/"
              Component={(props) => {
                if (state.step === 0 || state.step === undefined) return <MainPage />;
                if (state.step < 100) return <StepRouter {...props} />;
                if (state.step === 100) return <FinalPage />;
              }}
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </Row>
  );
};

export default MainRouter;
